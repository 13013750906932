import React, { useEffect, useState } from 'react'
import { getWindowDimension } from '../components/ui/responsive'

export const useWindowSize = () => {
  const [size, setSize] = useState(getWindowDimension());

  useEffect(() => {
    const onWindowResize = () => setSize(getWindowDimension());

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return size;
};
