import { useEffect } from 'react';
import { tag } from '../../lib/atinternet/ATInternetService';
import slugify from 'slugify';

export const PageTracker = ({name, level, chapter}) => {
  useEffect(() => {
    tag.sendPage({
      name: slugify(name, {remove: /[*+~.()'"!:@]/g}),
      level2: level ? level : null,
      chapter1: chapter ? slugify(chapter, {remove: /[*+~.()'"!:@]/g}) : null,
    });
  }, [name, level, chapter]);

  return null;
};