class ATInternetService {
  getNewTag(options = { secure: true }) {
    return new Promise(resolve => {
      try {
        // eslint-disable-next-line no-undef
        const instance = new ATInternet.Tracker.Tag(options);
        resolve(instance);
      } catch (ex) {
        console.error('Could not create ATInternet tracker tag');
      }
    });
  }

  // @param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
  sendPage(info) {
    this.getNewTag().then(tag => {
      tag.page.set(info);
      tag.dispatch();
    });
  }

  // @param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
  sendClick(info) {
    this.getNewTag().then(tag => {
      tag.click.send(info);
    });
  }

  sendCustomData(data) {
    this.getNewTag().then(tag => {
      tag.events.send('click.action', data);
    });
  }
}

export const tag = new ATInternetService();
