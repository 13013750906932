export const actions = {
  SET_QUESTION: 'SET_QUESTION',
  SET_CHOICE: 'SET_CHOICES',
  CHOOSE: 'CHOOSE',
  FETCH_QUESTION: 'FETCH_QUESTION',
  SET_PATH: 'SET_PATH',
  SET_POPUP_VIEWABLE: 'SET_POPUP_VIEWABLE',
  SET_INITIAL_CHOICES: 'SET_INITIAL_CHOICES',
  SET_CHOICE_SIMULATION: 'SET_CHOICE_SIMULATION',
  SET_RESULTS: 'SET_RESULTS'
}
