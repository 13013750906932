export const getWindowDimension = () => {
  const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
  return {width, height}
}

export const getBreakpoints = () => {
  return {
    'sm': 767,
    'md': 990,
    'xl': 1600
  }
}