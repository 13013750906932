import { tag } from '../../lib/atinternet/ATInternetService';

/**
 * Sends a user review.
 */
export const sendReview = (note, comment) => {
  tag.sendCustomData({
      'n:reviewnote': note,
      's:reviewcomment': comment,
  });
};
