import React from 'react';
import { BasicLayout } from '../components/layout/BasicLayout';
import Steps from '../modules/steps/Steps';
import LimitedContainer from '../components/ui/LimitedContainer';
import Popup from 'reactjs-popup';
import { BlockTitle } from '../components/ui/BlockTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupViewable } from '../modules/steps/redux/steps-actions';
import { getPopupViewable } from '../modules/steps/redux/steps-reducer';
import ClickTracker from '../components/tracking/ClickTracker';

export const HomePage = () => {
  const dispatch = useDispatch();
  const isPopupViewable = useSelector(getPopupViewable);

  return (
    <BasicLayout>
      <Steps />

      {isPopupViewable &&
      <Popup open onClose={() => { dispatch(setPopupViewable(false)); }}
             closeOnDocumentClick={false}>
        {close => (
          <div>
            <BlockTitle name="Rappel"/>
            <p>
              Pour une meilleure compréhension de votre complémentaire santé
              cette simulation vous permettra d’anticiper le reste à votre
              charge sur vos soins. Cette simulation n’est pas un devis et ne
              vaut pas engagement du remboursement réel pour lequel vous devez
              nous transmettre le devis du professionnel de santé en cliquant
              sur « Demander une estimation » dans la rubrique Mes
              Remboursements.
            </p>
            <ClickTracker chapter="Rappel" name="J'ai compris">
              <a className="btn btn--fill btn--upper" onClick={close}>J'ai
                compris</a>
            </ClickTracker>
          </div>
        )}
      </Popup>}
    </BasicLayout>
  );
};
