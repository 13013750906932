import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentQuestion, getPath } from './redux/steps-reducer'
import { setPath } from './redux/steps-actions'
import { Popover } from 'antd'
import PathPart from './PathPart'

const Path = () => {
  const path = useSelector(getPath)
  const question = useSelector(getCurrentQuestion)
  const dispatch = useDispatch()
  const pathContainerRef = useRef(null)
  const progressionContainerRef = useRef(null);

  useEffect(() => {
    const pathContainer = pathContainerRef.current;
    const lastPathElt = pathContainer && pathContainer.querySelector(`ul > li:nth-child(${path.length + 1})`)
    const progression = progressionContainerRef.current;

    if (progression && (question && question.choices.length > 0)) {
      progression.style.width = `${lastPathElt.offsetLeft + (lastPathElt.offsetWidth / 2)}px`
    } else {
      progression.style.width = '100%';
    }
  }, [path])

  return (
    <div className="path_container" ref={pathContainerRef}>
      <div className="progression" ref={progressionContainerRef}/>
      <ul>
        {
          path.length > 0 && path.map((choice, key) => {
            return <li key={key}>
              <PathPart choice={choice} index={key} />
            </li>
          })
        }
        <li>
          <span className="prev tablet-mobile-only" onClick={() => dispatch(setPath(path.length - 1, path[path.length - 1].category))}/>
          <span>{`Etape ${path.length + 1}`}</span>
        </li>
      </ul>
    </div>
  )
}

export default Path