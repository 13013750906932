import React, { useEffect, useState } from 'react'
import { DioptrySelector } from './DioptrySelector'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useDispatch, useSelector } from 'react-redux';
import { createChooseAction, setChoiceSimulation } from '../../redux/steps-actions'
import ClickTracker from '../../../../components/tracking/ClickTracker';
import { getCurrentQuestion } from '../../redux/steps-reducer';
import { getChoiceTypeNumberById } from '../../../../lib/api/choiceTypeInformation';

export const Dioptry = ({ choices }) => {
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(true);
  const choiceSimulation = choices[0].simulation[0];
  const question = useSelector(getCurrentQuestion);

  const setSimulation = (name, values) => {
    let simulationValue;
    if (name === 'all') {
      simulationValue = {
        'verreGauche': {
          ...values
        },
        'verreDroit': {
          ...values
        }
      }
    } else {
      simulationValue = {
        [name]: {
          ...values
        }
      }
    }

    dispatch(setChoiceSimulation(choices[0].category, choices[0].id, simulationValue));
  }

  const resetSimulation = () => {
    const resetSimulationValue = {
      'verreGauche': {
        'typeVerre': undefined
      },
      'verreDroit': {
        'typeVerre': undefined
      }
    };

    setDisabledButton(true)
    dispatch(setChoiceSimulation(choices[0].category, choices[0].id, resetSimulationValue))
  }

  useEffect(() => {
    (choiceSimulation.verreDroit.typeVerre && choiceSimulation.verreGauche.typeVerre) && setDisabledButton(false);
  },[choices]);

  return <div>
    <Tabs>
      <TabList>
          <Tab onClick={() => resetSimulation()}>
            <ClickTracker chapter={question.title} level={getChoiceTypeNumberById(choices[0].category)} name="Verres similaires" type="navigation">
              <span>Verres similaires</span>
            </ClickTracker>
          </Tab>

          <Tab onClick={() => resetSimulation()}>
            <ClickTracker chapter={question.title} level={getChoiceTypeNumberById(choices[0].category)} name="Verres différents" type="navigation">
              <span>Verres différents</span>
            </ClickTracker>
          </Tab>
      </TabList>

      <TabPanel>
        <DioptrySelector name="all" title="Verre n° 1 & 2" setSimulation={setSimulation} />
      </TabPanel>
      <TabPanel>
        <DioptrySelector name="verreGauche" title="Verre 1" setSimulation={setSimulation} />
        <DioptrySelector name="verreDroit" title="Verre 2" setSimulation={setSimulation} />
      </TabPanel>
    </Tabs>
    <ClickTracker chapter="Simuler le remboursement" level={getChoiceTypeNumberById(choices[0].category)} name="Simuler le remboursement">
      <a
        onClick={() => !disabledButton && dispatch(createChooseAction(choices[0].id, choices[0].category))}
        className="btn btn--transparent btn--center btn--upper"
        aria-disabled={disabledButton}
      >
        Simuler le remboursement
      </a>
    </ClickTracker>
  </div>
}