import { createReducer } from '../../../lib/redux/helper'
import { actions } from './constant'

const initialState = {
  review: {
    note: null,
    comment: '',
  },
  settings: {},
  lastReviewed: null,
};

const actionHandlers = {
  [actions.SET_REVIEW]: (state, { review: { note, comment } }) => ({
    ...state,
    review: {
      note,
      comment,
    },
    lastReviewed: (new Date()).getTime(),
  }),
  [actions.SET_REVIEW_SETTINGS]: (state, { settings: { key, value } }) => ({
    ...state,
    settings: {
      ...state.settings,
      [key.toLowerCase()]: value,
    },
  }),
};

export default createReducer(initialState, actionHandlers);

const local = (state) => state.review;

export const getReview = (state) => local(state).review;
export const getLastReviewDate = (state) => local(state).lastReviewed;
export const getReviewSettings = (state) => local(state).settings;
