import axios from 'axios';
import { getClientInformations } from './clientInformations';
import { getQueryParams } from '../helper/location';

const params = getQueryParams();

const hostname = window.location.hostname;
const configuration = getClientInformations(hostname);

const baseUrl = configuration.apiHost;
const apiKey = configuration.apiKey;

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${ params.token }`,
    apiKey: apiKey,
    Accept: 'application/json'
  },
});

export const getClient = async () => Promise.resolve(client);
