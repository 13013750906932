import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import { tag } from '../../lib/atinternet/ATInternetService';

/**
 * This components allow to track click on wrapped element.
 * Wrapped element should forward a ref.
 */
class ClickTracker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  handleClick = (event, originalClickCallback) => {
    const { name, type, level, chapter } = this.props;

    tag.sendClick({
      elem: this.element.current,
      name: slugify(name, {remove: /[*+~.()'"!:@]/g}),
      type,
      level2: level ? level : null,
      chapter1: slugify(chapter, {remove: /[*+~.()'"!:@]/g})
    });

    (typeof originalClickCallback === 'function') && originalClickCallback(event);
  };

  render() {
    return React.cloneElement(this.props.children, {
      ref: this.element,
      onClick: e => this.handleClick(e, this.props.children.props.onClick),
    });
  }
}

ClickTracker.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  level: PropTypes.number,
  chapter: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ClickTracker.defaultProps = {
  type: 'action',
};

export default ClickTracker;
