import React, { useEffect } from 'react';

import logo from '../../images/logo-hm.jpg'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCategory,
  getFirstname,
  getName,
} from '../../modules/steps/redux/steps-reducer';
import { setPath, setResults } from '../../modules/steps/redux/steps-actions';
import { fetchReviewSettings } from '../../modules/review/redux/review-actions';
import { getReviewSettings } from '../../modules/review/redux/review-reducer';
import ClickTracker from '../tracking/ClickTracker';
import { getChoiceTypeNumberById } from '../../lib/api/choiceTypeInformation';
import { Review } from '../../modules/review/Review';

const Header = () => {
  const firstname = useSelector(getFirstname);
  const name = useSelector(getName);
  const dispatch = useDispatch();
  const category = useSelector(getCategory);
  const reviewSettings = useSelector(getReviewSettings);

  useEffect(() => {
    dispatch(fetchReviewSettings());
  }, [dispatch]);

  return (
    <header className="app-header">
      <div>
        <ClickTracker chapter="Nouvelle estimation" name="Nouvelle estimation" level={getChoiceTypeNumberById(category)}>
          <a onClick={() => dispatch(setPath(0, "initial")) && dispatch(setResults([]))} className="btn btn--transparent btn--icon icon-estimation">
            <span>Nouvelle estimation</span>
          </a>
        </ClickTracker>
      </div>

      <div>
        <img src={logo} className="app-logo" alt="logo" />
      </div>

      <div className="adherent icon-user">
        <span>{firstname} {name}</span>
          {reviewSettings.review === '1' && <Review />}
      </div>
    </header>
  );
};

export default Header;
