import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLastChoice, getResults } from '../redux/steps-reducer'
import { getSimulation } from '../redux/steps-actions'
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs'
import { Result } from './Result'
import Loader from '../../../components/ui/Loader'
import { PageTracker } from '../../../components/tracking/PageTracker';
import { getChoiceTypeNumberById } from '../../../lib/api/choiceTypeInformation';

export const Simulation = () => {
  const lastChoice = useSelector(getLastChoice)
  const dispatch = useDispatch()
  const results = useSelector(getResults)

  const [inputs, setInputs] = useState({})

  useEffect(() => {
    dispatch(getSimulation())
  }, [lastChoice])

  const filterDuplicates = (tabs) => {
    const uniqueMap = []

    return tabs.filter(data => {
      const clean = (data) => ({
        remboursementMutuelleMaximum: data.remboursementMutuelleMaximum,
        remboursementRO: data.remboursementRO,
        participationForfaitaire: data.participationForfaitaire || 0,
      })

      const result = uniqueMap.reduce((acc, cur) => acc && JSON.stringify(clean(data)) !== cur, true)

      uniqueMap.push(JSON.stringify(clean(data)))

      return result
    })
  }

  const tabs = results ? filterDuplicates(results) : []

  useEffect(() => {
    setInputs({
      ...tabs.map(result => ([
        result.valeurMoyenneConstatee,
        result.monture ? result.monture.valeurMoyenneConstatee : 0
      ]))
    })
  }, [results])

  return <div>

    {tabs.length <= 0 && <Loader/>}

    {(tabs && tabs.length === 1) &&
      <div>
        <PageTracker name='Résultats' level={getChoiceTypeNumberById(lastChoice.category)}/>
        <Result
          result={tabs[0]}
          firstInput={(inputs[0] && inputs[0][0]) || ''}
          secondInput={(inputs[0] && inputs[0][1]) || ''}
          setFirstInput={(input) => setInputs({ ...inputs, [0]: [input, inputs[0][1]] })}
          setSecondInput={(input) => setInputs({ ...inputs, [0]: [inputs[0][0], input] })}
        />
      </div>
    }

    {tabs && tabs.length > 1 &&
    <Tabs>
      <TabList>
        {tabs.map((result, key) => {
          return <Tab key={key}>{result.label}</Tab>
        })}
      </TabList>

      {tabs.map((result, key) => {
        return <TabPanel className="simulation" key={key}>
          <PageTracker name={`Résultats ${result.label}`} level={getChoiceTypeNumberById(lastChoice.category)}/>
          <Result
            key={key}
            result={result}
            firstInput={(inputs[key] && inputs[key][0]) || ''}
            secondInput={(inputs[key] && inputs[key][1]) || ''}
            setFirstInput={(input) => setInputs({ ...inputs, [key]: [input, inputs[key][1]] })}
            setSecondInput={(input) => setInputs({ ...inputs, [key]: [inputs[key][0], input] })}
          />
        </TabPanel>
      })}
    </Tabs>
    }

    <p className="legals">
      L’estimation du reste à charge présentée s’appuie sur les prestations versées pour des bénéficiaires dans votre
      situation, affiliés
      au régime général de la sécurité sociale.<br/>
      Cette estimation n’est pas un devis et ne vaut pas engagement de la mutuelle sur votre futur remboursement pour
      les frais
      réellement engagés. En cas de besoin d’informations complémentaires ou de devis réel nous vous invitons à
      contacter votre
      conseiller Harmonie Mutuelle à partir des informations fournies sur votre application mobile, votre portail
      adhérent, votre carte
      Mutuelle, le site Harmonie Mutuelle ou la description de votre garantie.
    </p>
  </div>
}