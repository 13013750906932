import React, { useState } from 'react'
import Popup from 'reactjs-popup'

export const BlockTitle = ({ name, description }) => {
  const [isDescriptionViewable, setDescriptionViewable] = useState(false)

  return <div>
    <h2>{name} {description && <span className='desc' onClick={(e) => {
      setDescriptionViewable(!isDescriptionViewable)
      e.stopPropagation()
    }}>?</span>}</h2>

    {description && <Popup className="popup-desc" open={isDescriptionViewable} closeOnDocumentClick={true}>
      {close => (
        <div>
          <button className="close"
                  onClick={() => setDescriptionViewable(!isDescriptionViewable) && close}>&times;</button>
          <div className="desc-content" dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
      )}
    </Popup>}
  </div>
}