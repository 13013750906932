import React from 'react'
import { useSelector } from 'react-redux'
import { getLastChoice } from '../redux/steps-reducer'
import { getBreakpoints } from '../../../components/ui/responsive'
import { useWindowSize } from '../../../hooks/use-window-size';

export const Result = ({ result, firstInput, secondInput, setFirstInput, setSecondInput }) => {
  const breakpoints = getBreakpoints()
  const { width } = useWindowSize()

  const lastChoice = useSelector(getLastChoice)
  const franchise = result.participationForfaitaire ? result.participationForfaitaire : 0

  let rac;
  let remboursementMutuelle;
  let remboursementRO;
  if (lastChoice.category === 'optiques' && result.label !== 'Monture' && (result.monture && result.monture.valeurMoyenneConstatee > 0)) {
    const racVerres = parseFloat(firstInput || 0) - result.remboursementMutuelleMaximum - result.remboursementRO;
    const racMonture = parseFloat(secondInput || 0) - result.monture.remboursementMutuelleMaximum - result.monture.remboursementRO;

    rac = (racVerres <= 0 ? 0 : racVerres) + (racMonture  <= 0 ? 0 : racMonture);

    let remboursementMutuelleVerre = parseFloat(firstInput || 0) - result.remboursementRO;
    if (remboursementMutuelleVerre > result.remboursementMutuelleMaximum) {
      remboursementMutuelleVerre = result.remboursementMutuelleMaximum;
    }

    let remboursementMutuelleMonture = parseFloat(secondInput || 0) - result.monture.remboursementRO;
    if (remboursementMutuelleMonture > result.monture.remboursementMutuelleMaximum) {
      remboursementMutuelleMonture = result.monture.remboursementMutuelleMaximum;
    }

    remboursementMutuelle = remboursementMutuelleVerre + remboursementMutuelleMonture;

    remboursementRO = result.remboursementRO + result.monture.remboursementRO;
  } else {
    rac = ((parseFloat(firstInput || 0) + parseFloat(secondInput || 0)) - result.remboursementMutuelleMaximum - result.remboursementRO);
    remboursementMutuelle = ((parseFloat(firstInput || 0) + parseFloat(secondInput || 0)) - result.remboursementRO);
    remboursementRO = result.remboursementRO - franchise;

    if (remboursementRO > result.remboursementRO) {
      remboursementRO = result.remboursementRO;
    }

    if (remboursementMutuelle > result.remboursementMutuelleMaximum) {
      remboursementMutuelle = result.remboursementMutuelleMaximum;
    }
  }

  if (remboursementMutuelle <= 0) {
    remboursementMutuelle = Number.parseFloat('0').toFixed(2);
  } else {
    remboursementMutuelle = Math.round(remboursementMutuelle*100)/100;
  }

  if (remboursementRO <= 0) {
    remboursementRO = Number.parseFloat('0').toFixed(2);
  } else {
    remboursementRO = Math.round(remboursementRO*100)/100;
  }

  const total = (rac <= 0 ? 0 : rac) + remboursementMutuelle + (remboursementRO <= 0 ? 0 : remboursementRO);

  let racStyle
  let mutuelleStyle
  let roStyle

  if (width > breakpoints.md) {
    racStyle = {
      width: `${rac <= 0 ? 0 : rac * 100 / total}%`
    }

    mutuelleStyle = {
      width: `${remboursementMutuelle * 100 / total}%`
    }

    roStyle = {
      width: `${remboursementRO <= 0 ? 0 : remboursementRO * 100 / total}%`
    }
  } else {
    racStyle = {
      height: `${rac <= 0 ? 0 : rac * 100 / total}%`,
    }

    mutuelleStyle = {
      height: `${remboursementMutuelle * 100 / total}%`
    }

    roStyle = {
      height: `${remboursementRO <= 0 ? 0 : remboursementRO * 100 / total}%`
    }
  }

  return <div>
    <div className="timeline-form">
      {lastChoice.category !== 'optiques' && <p>
        Pour une dépense prévue de
        <input
          type="number"
          pattern="[0-9]*"
          value={firstInput}
          step={1}
          onChange={e => setFirstInput(e.target.value)}
        /> € pour le soin demandé.
      </p>}

      {lastChoice.category === 'optiques' && result.label === 'Monture' && <p>
        Pour une dépense prévue de
        <input
          type="number"
          pattern="[0-9]*"
          value={firstInput}
          step={1}
          onChange={e => setFirstInput(e.target.value)}
        /> € pour la monture chez un opticien.
      </p>}

      {lastChoice.category === 'optiques' && result.label !== 'Monture' && (result.monture && result.monture.valeurMoyenneConstatee > 0) &&
      <p>
        Pour une dépense prévue de
        <input
          type="number"
          pattern="[0-9]*"
          value={firstInput}
          onChange={e => setFirstInput(e.target.value)}
        /> € pour les verres et de
        <input
          type="number"
          pattern="[0-9]*"
          value={secondInput}
          onChange={e => setSecondInput(e.target.value)}
        /> € pour la monture chez un opticien.
      </p>}

      {lastChoice.category === 'optiques' && result.label !== 'monture' && (result.monture && result.monture.valeurMoyenneConstatee <= 0) && <p>
        Pour une dépense prévue de
        <input
          type="number"
          pattern="[0-9]*"
          value={firstInput}
          onChange={e => setFirstInput(e.target.value)}
        /> € pour les verres chez un opticien.
      </p>}
    </div>

    <div className="timeline">
      <div className={`rac ${rac <= 0 && 'null'}`} style={racStyle}>
        <div className="timeline-tooltip left">
          <p>{rac < 0 ?  Number.parseFloat('0').toFixed(2) : Number.parseFloat(rac).toFixed(2)} € <br/><span className="light">de reste à payer par vous</span></p>
        </div>
      </div>

      <div className="mutuelle" style={mutuelleStyle}>
        <div className="timeline-tooltip center">
          <p>{Number.parseFloat(remboursementMutuelle).toFixed(2)} € <br/><span className="light">de remboursement mutuelle</span></p>
        </div>
      </div>

      <div className={`ro ${franchise > 0 && 'franchise'}`} style={roStyle}>
        <div className="timeline-tooltip right">
          <p>{Number.parseFloat(remboursementRO).toFixed(2)} € <br/><span className="light">de remboursement sécurité sociale</span></p>
          {franchise > 0 && <span className="franchise">Franchise de {franchise} €</span>}
        </div>
      </div>
    </div>
  </div>
}
