export const getChoiceTypeNumberById = $id => {
  switch ($id) {
    case 'optiques':
      return 2;
    case 'honoraires':
      return 3;
    case 'dentaires':
      return 4;
    default:
      return 1;
  }
}