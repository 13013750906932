import { makeActionCreator } from '../../../lib/redux/helper';
import { actions } from './constant';
import { fetchReviewSettings as fetchSettings } from '../../../lib/api/review';

export const setReview = makeActionCreator(actions.SET_REVIEW, 'review');
const setReviewSettings = makeActionCreator(actions.SET_REVIEW_SETTINGS, 'settings');

export const fetchReviewSettings = () => (dispatch) => {
  fetchSettings().then(settings => {
    settings && settings.data && dispatch(setReviewSettings(settings.data));
  });
};
