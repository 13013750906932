import queryString from 'query-string';

export const getQueryParams = () => {
  const parameters = queryString.parse(window.location.search);

  return Object.entries(parameters).reduce((acc, [key, value]) => ({
    ...acc,
    [key !== 'token' ? key.toLowerCase() : key]: value,
  }), {});
};
