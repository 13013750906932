const add = (a = {}, b = {}) => [a, b].reduce((acc, cur) => ({
  ...acc,
  ...cur,
  remboursementMutuelleMaximum: (cur.remboursementMutuelleMaximum || 0) + (acc.remboursementMutuelleMaximum || 0),
  remboursementRO: (cur.remboursementRO || 0) + (acc.remboursementRO || 0),
  valeurMoyenneConstatee: (cur.valeurMoyenneConstatee || 0) + (acc.valeurMoyenneConstatee || 0),
  participationForfaitaire: (cur.participationForfaitaire || 0) + (acc.participationForfaitaire || 0),
}), {});

const mapMonture = (monture = {}) => ({
  monture: {
    valeurMoyenneConstatee: monture.valeurMoyenneConstatee || 0,
    remboursementMutuelleMaximum: monture.remboursementMutuelleMaximum || 0,
    remboursementRO: monture.remboursementRO
  }
})

/**
 * Normalize optique simulation response.
 */
export const normalize = (response) => {
  const { monture, kalivia, kalixia, horsKalivia, horsKalixia } = response;

  return [
    (kalivia || kalixia) && {
      ...add(kalivia.verreDroit, kalivia.verreGauche),
      ...mapMonture(monture),
      label: 'Kalixia',
    },
    (horsKalivia || horsKalixia) && {
      ...add(horsKalivia.verreDroit, horsKalivia.verreGauche),
      ...mapMonture(monture),
      label: 'Hors réseaux',
    },
    !kalivia && !horsKalivia && monture && {
      ...monture,
      label: 'Monture',
    },
  ].filter(a => a);
}