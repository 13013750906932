import React from 'react'

const RadioButton = React.forwardRef(({value, name, label, onClick}, ref) => {
  return <label className="input">
    <input ref={ref} type="radio" name={ name } value={ value } onClick={onClick} />
    <span className="checked" />
    { label }
  </label>;
})

export default RadioButton;