import React from 'react'
import Step from './Step'
import Path from './Path'

const Steps = () => {
  return <div className="steps">
    <Path/>
    <Step/>
  </div>
}

export default Steps