import { makeActionCreator } from '../../../lib/redux/helper'
import { getCurrentCategory, getLastChoice } from './steps-reducer'
import { actions } from './constant'
import { fetchInitialChoices, fetchQuestionByCategory, fetchSimulation } from '../../../lib/api/steps'
import { normalize } from '../../../lib/normalizer/optique-normalizer'
import { getQueryParams } from '../../../lib/helper/location';

const params = getQueryParams();

const createFetchQuestionAction = makeActionCreator(actions.FETCH_QUESTION, 'id', 'category');
const createAddQuestionAction = makeActionCreator(actions.SET_QUESTION, 'data', 'category');
const createAddChoicesAction = makeActionCreator(actions.SET_CHOICE, 'data', 'category');
export const setResults = makeActionCreator(actions.SET_RESULTS, 'data');
export const createChooseAction = makeActionCreator(actions.CHOOSE, 'value', 'category');
export const setPath = makeActionCreator(actions.SET_PATH, 'key', 'category');
export const setPopupViewable = makeActionCreator(actions.SET_POPUP_VIEWABLE, 'value');
export const setChoiceSimulation = makeActionCreator(actions.SET_CHOICE_SIMULATION, 'category', 'choiceId', 'value');


export const fetchQuestion = (questionId) => (dispatch, getState) => {
  const lastChoice = getLastChoice(getState());

  dispatch(createFetchQuestionAction(questionId, lastChoice.type))

  if (lastChoice.type !== 'optiques') {
    fetchQuestionByCategory(lastChoice.type, lastChoice.nextQuestionId).then(data => {
      const question = {
        id: lastChoice.nextQuestionId,
        questionType: 'simple',
        title: data.question,
        description: data.description
      }

      dispatch(createAddQuestionAction(question, lastChoice.type));

      const choices = data.reponses.map(reponse => ({
        id: reponse.id,
        name: reponse.reponse,
        questionId: reponse.questionId,
        nextQuestionId: reponse.nextQuestionId,
        description: reponse.description,
        simulation: reponse.categories.map(cat => ({
          ...cat,
          'champs': cat.champs.map(champ => ({
            ...champ,
            'valeur': 10000
          }))
        }))
      }));

      choices.map(choice => dispatch(createAddChoicesAction(choice, lastChoice.type)));
    });
  }
}

export const fetchInitialDisplay = () => (dispatch) => {
  fetchInitialChoices().then(choices => {
    dispatch(createAddQuestionAction({
      id: 0,
      title: 'De quel type de soin s\'agit-il ?',
      questionType: 'simple',
    }, 'initial'));

    choices.map(choice => dispatch(createAddChoicesAction(choice, 'initial')));
  });
}

export const getSimulation = () => (dispatch, getState) => {
  const lastChoice = getLastChoice(getState());

  if (lastChoice.category === 'optiques' && lastChoice.simulation) {
    fetchSimulation(lastChoice.category, lastChoice.simulation[0]).then(data => {
      dispatch(setResults(normalize(data)));
    });
  } else {
    fetchSimulation(lastChoice.category, {
      'age': params.age,
      'categories': lastChoice.simulation
    }).then(data => {
      dispatch(setResults(data.map(value => {
        let label;

        if (value.optam) {
          label = value.optam;
        }

        return ({
          ...value,
          'label': label
        });
      })));
    });
  }
}
