import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getChoices,
  getCurrentQuestion,
  getLastChoice,
  isQuestionFetching, getCurrentType, getPath
} from './redux/steps-reducer'
import { createChooseAction, fetchInitialDisplay, fetchQuestion } from './redux/steps-actions'
import { BlockCategory } from '../../components/ui/BlockCategory'
import { Choices } from './Step/Choices'
import Loader from '../../components/ui/Loader'
import { Simulation } from './Step/Simulation'
import { BlockTitle } from '../../components/ui/BlockTitle'
import { PageTracker } from '../../components/tracking/PageTracker';
import { getChoiceTypeNumberById } from '../../lib/api/choiceTypeInformation';

const Step = () => {
  const lastChoice = useSelector(getLastChoice)
  const question = useSelector(getCurrentQuestion)
  const choices = useSelector(getChoices)
  const dispatch = useDispatch()
  const fetchingQuestion = useSelector(isQuestionFetching)
  const type = useSelector(getCurrentType)
  const path = useSelector(getPath)

  useEffect(() => {
    if (lastChoice !== undefined) {
      if (lastChoice.simulation && lastChoice.simulation.length > 0) {
        return;
      }

      fetchingQuestion && dispatch(fetchQuestion(lastChoice.nextQuestionId));
    } else {
      fetchingQuestion && dispatch(fetchInitialDisplay());
    }

  }, [lastChoice])

  const onChoice = (value) => {
    dispatch(createChooseAction(value, type))
  }

  return (
    (!fetchingQuestion && question && <div className="step">
      { question.choices.length > 0 && <BlockCategory name={`Etape ${ path.length + 1 }`}/> }
      { question.choices.length > 0 && <PageTracker name={question.title} level={getChoiceTypeNumberById(choices[0].category)} /> }
      <BlockTitle name={ question.title } description={question.description}/>
      <Choices type={ question.questionType } choices={ choices } onChange={onChoice} />
    </div> ) ||
    (lastChoice && lastChoice.simulation && lastChoice.simulation.length > 0 && <Simulation />) ||
    <Loader />
  )

}

export default Step