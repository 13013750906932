export const getClientInformations = hostname => {
  switch (hostname) {
    case 'irma2.harmonie-mutuelle.fr':
      return {
        apiHost: 'https://api.harmonie-mutuelle.fr/services/irma/irma-api/v1',
        apiKey: 'b565822b-39ce-4a04-8189-3ace94cb329a',
      }
    default:
      return {
        apiHost: 'https://rec.api.harmonie-mutuelle.fr/services/irma/irma-api/v1',
        apiKey: '7bc145b5-8460-4dce-80d2-b33399fcaf16',
      }
  }
}
