import { getClient } from './client';

export const fetchReviewSettings = async () => {
  const client = await getClient();

  try {
    return await client.get('/configurations/Review').catch(error => {
      console.error('Failed to read review settings ->', error);

      return null;
    });
  } catch (error) {
    console.error('Failed to read review settings ->', error);
  }
};
