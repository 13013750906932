import React from 'react'

/**
 * This components renders all children in a limited width container.
 */
const LimitedContainer = ({ children }) => (
  <div className="limited-content">
    {children}
  </div>
)

export default LimitedContainer
