const location = window.location;
const hostname = location.hostname;

export const setSmartTag = () => {
  const script = document.createElement('script');
  script.type = 'application/javascript';

  switch (hostname) {
    case 'irma2.harmonie-mutuelle.fr':
      script.src = '//tag.aticdn.net/613603/smarttag.js';
      break;
    default:
      script.src = '//tag.aticdn.net/613602/smarttag.js';
      break;
  }

  document.getElementsByTagName('head')[0].appendChild(script);
}
