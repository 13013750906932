import { getClient } from './client';
import { getQueryParams } from '../helper/location';

const params = getQueryParams();

const initialChoices = {
  'honoraires': {
    id: 'honoraires',
    name: 'Honoraires médicaux',
    questionId: 0,
    nextQuestionId: 0,
  },
  'optiques': {
    id: 'optiques',
    name: 'Optique',
    questionId: 0,
    nextQuestionId: 0,
  },
  'dentaires': {
    id: 'dentaires',
    name: 'Dentaire',
    questionId: 0,
    nextQuestionId: 0,
  }
};

export const fetchInitialChoices = async () => {
  const client = await getClient();
  const response = await client.get(`/produits/${params.product}`);

  return response.data.map(choice => ({
    ...{
      id: choice.type,
      name: choice.type.charAt(0).toUpperCase() + choice.type.slice(1), // ucfirst
      questionId: 0,
      nextQuestionId: 0,
    },
    ...initialChoices[choice.type],
    ...choice,
    nextQuestionId: choice.idQuestion || 0,
  }));
};

export const fetchQuestionByCategory = async (category, questionId) => {
  const client = await getClient();
  const response = await client.get(`/${category}/questions/${questionId}?age=${params.age}`);

  return response.data;
};

export const fetchSimulation = async (category, simulation) => {
  const client = await getClient();
  const response = await client.post(
    `/${category}/simulations/${params.product}`,
    simulation
  );

  return response.data;
};
