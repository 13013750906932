import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { useSelector } from 'react-redux';
import { getCurrentQuestion } from '../../modules/steps/redux/steps-reducer';
import ClickTracker from '../tracking/ClickTracker';
import { getChoiceTypeNumberById } from '../../lib/api/choiceTypeInformation';

const SquareChoice = ({ choice, onChange }) => {
  const [isDescriptionViewable, setDescriptionViewable] = useState(false)
  const question = useSelector(getCurrentQuestion);

  return <ClickTracker chapter={question.title} level={getChoiceTypeNumberById(choice.category)} name={choice.name} type="navigation">
    <li
      key={choice.id}
      onClick={() => !isDescriptionViewable && onChange(choice.id)}>
      {choice.description && <Popup className="popup-desc" open={isDescriptionViewable} closeOnDocumentClick={true}>
        {close => (
          <div>
            <button className="close"
                    onClick={() => setDescriptionViewable(!isDescriptionViewable) && close}>&times;</button>
            <div className="desc-content" dangerouslySetInnerHTML={{ __html: choice.description }}/>
          </div>
        )}
      </Popup>}
      <p>
        {choice.name}
        {choice.description &&
        < span className='desc' onClick={(e) => {
          setDescriptionViewable(!isDescriptionViewable)
          e.stopPropagation()
        }}>?</span>
        }
      </p>
    </li>
  </ClickTracker>
}

export default SquareChoice