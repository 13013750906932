import React from 'react'
import { PageTracker } from '../../../../components/tracking/PageTracker';
import { getChoiceTypeNumberById } from '../../../../lib/api/choiceTypeInformation';

export const FullHealOptiques = () => {
  return <div className="full-text">
    <PageTracker level={getChoiceTypeNumberById('optiques')} name='100% santé' />
    <p>
      Depuis le 1er janvier 2020, les opticiens proposent une gamme « 100 % Santé », composée d’une sélection de
      lunettes
      de vue de qualité (monture et verres), intégralement prises en charge par l’Assurance Maladie et la complémentaire
      santé (mutuelle).
    </p>

    <p>
      Si cette proposition ne vous convient pas, une paire différente peut vous être proposée :
    </p>

    <ul>
      <li>soit monture du panier 100% Santé + verres à tarifs libres</li>
      <li>soit monture à tarif libre + verres du panier 100% Santé</li>
      <li>soit monture + verres à tarifs libres</li>
    </ul>

    <p>
      <strong>Pour de plus amples informations :</strong><br />
      <a target="_blank" href="https://www.harmonie-mutuelle.fr/web/particuliers/sante/complementaire-sante/100-pour-cent-sante">
        https://www.harmonie-mutuelle.fr/web/particuliers/sante/complementaire-sante/100-pour-cent-sante
      </a>
      <a target="_blank" href="https://www.ameli.fr/hauts-de-seine/assure/remboursements/rembourse/optique-audition/lunettes-lentilles">
        https://www.ameli.fr/hauts-de-seine/assure/remboursements/rembourse/optique-audition/lunettes-lentilles
      </a>
    </p>
  </div>
}