import { createReducer } from '../../../lib/redux/helper'
import { getQueryParams } from '../../../lib/helper/location';
import { actions } from './constant'

const params = getQueryParams();

const initialState = {
  popupViewable: true,
  category: 'initial',
  defaultQuestion: {
    category: 'initial',
    id: 0,
  },
  adherent: {
    name: params.name || null,
    firstname: params.firstname || null,
    age: params.age || null,
    product: params.product || null
  },
  optiques: {
    questions: {
      0: {
        id: 0,
        title: 'Est-ce que l’équipement choisi est « 100% Santé » ?',
        questionType: 'simple',
        choices: [1, 2]
      },
      1: {
        id: 1,
        title: '100% santé',
        questionType: 'fullHealOptiques',
        choices: []
      },
      2: {
        id: 2,
        title: 'Quel équipement souhaitez-vous ?',
        questionType: 'simple',
        choices: [3, 4, 5]
      },
      3: {
        id: 3,
        title: 'De quel type de verre avez-vous besoin ?',
        questionType: 'dioptry',
        choices: [6]
      },
      4: {
        id: 4,
        title: 'De quel type de verre avez-vous besoin ?',
        questionType: 'dioptry',
        choices: [7]
      }
    },
    choices: {
      1: {
        id: 1,
        name: 'Oui',
        category: 'optiques',
        questionId: 0,
        nextQuestionId: 1,
        type: 'optiques'
      },
      2: {
        id: 2,
        name: 'Non',
        category: 'optiques',
        questionId: 0,
        nextQuestionId: 2,
        type: 'optiques'
      },
      3: {
        id: 3,
        name: 'Monture seule',
        category: 'optiques',
        questionId: 2,
        nextQuestionId: null,
        type: 'optiques',
        simulation: [
          {
            'age': params.age,
            'monture': {
              'valeur': 10000
            }
          }
        ]
      },
      4: {
        id: 4,
        name: 'Monture et verres',
        category: 'optiques',
        questionId: 2,
        nextQuestionId: 4,
        type: 'optiques'
      },
      5: {
        id: 5,
        name: 'Verres seuls',
        category: 'optiques',
        questionId: 2,
        nextQuestionId: 3,
        type: 'optiques'
      },
      6: {
        id: 6,
        name: 'dioptry',
        category: 'optiques',
        questionId: 3,
        nextQuestionId: null,
        type: 'optiques',
        simulation: [
          {
            'age': params.age,
            'verreDroit': {
              'valeur':10000,
              'correction': 0
            },
            'verreGauche': {
              'valeur':10000,
              'correction': 0
            }
          }
        ]
      },
      7: {
        id: 7,
        name: 'dioptry',
        category: 'optiques',
        questionId: 4,
        nextQuestionId: null,
        type: 'optiques',
        simulation: [
          {
            'age': params.age,
            'monture': {
              'valeur':10000
            },
            'verreDroit': {
              'valeur':10000,
              'correction': 0
            },
            'verreGauche': {
              'valeur':10000,
              'correction': 0
            }
          }
        ]
      }
    },
    fetchQuestion: {
      0: false,
      1: false,
      2: false,
      3: false,
    },
  },
  results: [],
  path: [],
};

const actionHandlers = {
  [actions.CHOOSE]: (state, { value, category }) => ({
    ...state,
    path: [...state.path, {
      value: value,
      category
    }],
    category: state[category].choices[value] ? state[category].choices[value].type : category
  }),
  [actions.FETCH_QUESTION]: (state, { id, category }) => {
    const currentCategory = state[category] || {
      questions: {},
      choices: {},
      fetchQuestion: {},
    };

    return  {
      ...state,
      [category]: {
        ...currentCategory,
        fetchQuestion: {
          ...currentCategory.fetchQuestion,
          [id]: true
        }
      }
    }
  },
  [actions.SET_QUESTION]: (state, { data: { id, title, questionType, description }, category }) => {
    const currentCategory = state[category] || {
      questions: {},
      choices: {},
      fetchQuestion: {},
    };

    return {
      ...state,
      [category]: {
        ...currentCategory,
        questions: {
          ...currentCategory.questions,
          [id]: {
            id,
            title,
            questionType,
            description,
            choices: [],
          }
        },
        fetchQuestion: { ...currentCategory.fetchQuestion, [id]: false }
      }
    };
  },
  [actions.SET_CHOICE]: (state, { data: { id, name, nextQuestionId, questionId, simulation, description }, category }) => ({
    ...state,
    [category]: {
      ...state[category],
      choices: {
        ...state[category].choices,
        [id]: {
          id,
          name,
          category: category,
          questionId: questionId,
          nextQuestionId: nextQuestionId,
          type: Number.isInteger(id) ? category : id,
          simulation,
          description
        }
      },
      questions: {
        ...state[category].questions,
        [questionId]: {
          ...state[category].questions[questionId],
          choices: [
            ...state[category].questions[questionId].choices,
            id
          ]
        }
      }
    }
  }),
  [actions.SET_PATH]: (state, { key, category }) => ({
      ...state,
      path: state.path.slice(0, key),
      category: category
  }),
  [actions.SET_POPUP_VIEWABLE]: (state, { value }) => ({
    ...state,
    popupViewing: value
  }),
  [actions.SET_CHOICE_SIMULATION]: (state, { category, choiceId, value }) => ({
      ...state,
      [category]: {
        ...state[category],
        choices: {
          ...state[category].choices,
          [choiceId]: {
            ...state[category].choices[choiceId],
            simulation: Object.values({
              ...state[category].choices[choiceId].simulation,
              0: {
                ...state[category].choices[choiceId].simulation[0],
                'verreDroit': {
                  ...state[category].choices[choiceId].simulation[0]['verreDroit'],
                  ...value.verreDroit
                },
                'verreGauche': {
                  ...state[category].choices[choiceId].simulation[0]['verreGauche'],
                  ...value.verreGauche
                },
              }
            })
          }
        }
      }
  }),
  [actions.SET_RESULTS]: (state, { data }) => ({
    ...state,
    results: data
  })
};

export default createReducer(initialState, actionHandlers)

const local = (state) => state.steps;

export const getName = (state) => local(state).adherent.name;
export const getFirstname = (state) => local(state).adherent.firstname;

export const getPopupViewable = (state) => local(state).popupViewable ?? true;

export const getCurrentType = (state) => local(state).category;

export const getChoice = (state, id, category) => id && category ? local(state)[category].choices[id] : undefined;

export const getPath = (state) => local(state).path.length > 0 ? local(state).path.map(choice => getChoice(state, choice.value, choice.category)) : [];

export const getLastChoice = (state) =>
  getChoice(
    state,
    local(state).path[local(state).path.length - 1] ? local(state).path[local(state).path.length - 1].value : undefined,
    local(state).path[local(state).path.length - 1] ? local(state).path[local(state).path.length - 1].category : undefined
  );

export const getCurrentQuestion = (state) => {
   const lastChoice = getLastChoice(state);
   const s = local(state);

   if (lastChoice) {
     if (!s[getCurrentType(state)]) {
       return null;
     }

     return s[getCurrentType(state)].questions[lastChoice.nextQuestionId];
   }

   if (s[s.defaultQuestion.category]) {
     return s[s.defaultQuestion.category].questions[s.defaultQuestion.id];
   }

   return null;
}

export const getQuestionById = (state, category, questionId) => {
  const s = local(state);

  return s[category].questions[questionId];
}

export const getChoices = (state) => {
  const currentQuestion = getCurrentQuestion(state);

  if (!currentQuestion || !currentQuestion.choices) {
    return [];
  }

  return currentQuestion.choices.map(id => getChoice(state, id, getCurrentType(state)))
};

export const isQuestionFetching = (state) => getCurrentQuestion(state) ? local(state)[getCurrentType(state)].fetchQuestion[getCurrentQuestion(state).id] : true;

export const getCurrentCategory = (state) => getChoices(state) && getChoices(state).length > 0 ? getChoices(state)[0].category : null;

export const getResults = (state) => local(state).results;

export const hasResults = (state) => local(state).results.length > 0;

export const getCategory = (state) => local(state).category;
