import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import { ReviewForm } from './ReviewForm';
import { hasResults } from '../steps/redux/steps-reducer';
import { getReview } from './redux/review-reducer';
import { sendReview } from '../../lib/review/review';

const reviewAutoOpenDelay = 5000;

/**
 * This components shows a button designed for user to give his review about the service.
 * Review popup can be triggered automatically when simulation results are retrieved (see related delay above).
 */
export const Review = () => {
  const [isPopupOpened, setPopupOpened] = useState(false);
  const isProcessFinished = useSelector(hasResults);
  const lastReview = useSelector(getReview);

  const closePopup = () => {
    setPopupOpened(false);
  };

  const openPopup = event => {
    event && event.preventDefault();
    setPopupOpened(true);
  };

  useEffect(() => {
    if (!isProcessFinished || isPopupOpened) {
      return () => {};
    }

    let timer;

    const startTimer = () => {
      timer = setTimeout(() => {
        timer = null;
        setPopupOpened(true);
      }, reviewAutoOpenDelay);
    };

    const stopTimer = () => {
      timer && clearTimeout(timer);
    };

    const onUserAction = () => {
      if (timer === null) {
        return;
      }

      stopTimer();
      startTimer();
    };

    document.addEventListener('scroll', onUserAction);
    document.addEventListener('mousedown', onUserAction);
    [...document.querySelectorAll('input, select, textarea')].forEach(element => {
      element.addEventListener('input', onUserAction);
    });

    startTimer();

    return () => {
      stopTimer();
      document.removeEventListener('scroll', onUserAction);
      document.removeEventListener('mousedown', onUserAction);
      [...document.querySelectorAll('input, select, textarea')].forEach(element => {
        element.removeEventListener('input', onUserAction);
      });
    };
  }, [isProcessFinished]);

  useEffect(() => {
    if (lastReview.note === null) {
      return;
    }

    isPopupOpened && closePopup();
    sendReview(lastReview.note, lastReview.comment);
  }, [lastReview]);

  return (
      <div className="review">
        <a className="btn btn--fill" onClick={openPopup}>Je donne mon avis</a>
        {isPopupOpened && <Popup open onClose={closePopup}>
          {close => <>
            <div className="close-icon" onClick={close} />
            <ReviewForm />
          </>}
        </Popup>}
      </div>
  );
};
