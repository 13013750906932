import React, { useEffect, useState } from 'react'
import { getBreakpoints } from './responsive'
import { useWindowSize } from '../../hooks/use-window-size'
import SquareChoice from './SquareChoice'

const SquareChoices = ({ choices, onChange }) => {
  const breakpoints = getBreakpoints()
  const { width } = useWindowSize()

  const [currentIndex, setIndex] = useState(0)
  const choicesDisplaying = width > breakpoints.md ? choices.slice(currentIndex, currentIndex + 4) : choices

  useEffect(() => {
    setIndex(0);
  }, [choices]);

  return <ul className="squareChoices">
    {width > breakpoints.md &&
    choices.length > 4 &&
    currentIndex > 0 &&
    <li className="navigation" onClick={() => {setIndex(currentIndex - 4)}}>
      <span className="icon-arrow-left"></span> Retour
    </li>}

    {choicesDisplaying.map((choice, key) => {
      return <SquareChoice choice={choice} onChange={onChange} key={key} />
    })}

    {width > breakpoints.md &&
    choices.length > 4 &&
    currentIndex === 0 &&
    <li className="navigation" onClick={() => {setIndex(currentIndex + 4)}}>
      Autre <span>+</span>
    </li>}

    {width > breakpoints.md &&
    choices.length > 8 &&
    currentIndex > 0 &&
    <li className="navigation" onClick={() => {setIndex(currentIndex + 4)}}>
      Plus <span>+</span>
    </li>}
  </ul>
}

export default SquareChoices