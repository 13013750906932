import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getQuestionById } from './redux/steps-reducer'
import { setPath, setResults } from './redux/steps-actions';
import { Popover } from 'antd'
import ClickTracker from '../../components/tracking/ClickTracker';
import { getChoiceTypeNumberById } from '../../lib/api/choiceTypeInformation';

const PathPart = ({choice, index}) => {
  const dispatch = useDispatch();
  const question = useSelector(state => getQuestionById(state, choice.category, choice.questionId));
  
  return (
    <ClickTracker chapter={question.title} level={getChoiceTypeNumberById(choice.category)} name={`Etape ${index + 1}`}>
      <Popover placement='bottom' content={question.title} title={`Etape ${index + 1}`} trigger="hover">
          <span onClick={() => dispatch(setPath(index, choice.category)) && dispatch(setResults([]))}>
            {`Etape ${index + 1}`}
          </span>
      </Popover>
    </ClickTracker>
  )
}

export default PathPart;