import React from "react";
import SquareChoices from '../../../components/ui/SquareChoices'
import { Dioptry } from './Choices/Dioptry'
import { FullHealOptiques } from './Choices/FullHealOptiques'

const guessComponent = (type) => {
  switch (type) {
    case "simple":
      return SquareChoices;
    case "dioptry":
      return Dioptry;
    case "fullHealOptiques":
      return FullHealOptiques;
    default:
      return null;
  }
};

export const Choices = ({ type, choices, onChange }) => {
  const Component = guessComponent(type);
  return <>
    { Component && <Component choices={choices} onChange={onChange} /> }
  </>
};
