import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import { setReview } from './redux/review-actions';
import { getLastReviewDate } from './redux/review-reducer';

/**
 * Shows a form to let a review about the service.
 */
export const ReviewForm = () => {
  const dispatch = useDispatch();
  const lastReview = useSelector(getLastReviewDate);
  const [note, setNote] = useState(null);
  const [comment, setComment] = useState('');
  const noteCount = 5;

  const onSubmit = event => {
    event.preventDefault();
    dispatch(setReview({ note, comment }));
  };

  useEffect(() => {
    setNote(null);
    setComment('');
  }, [lastReview]);

  return (
      <div className="review-form">
        <h2>Je donne mon avis</h2>
        <p>
          Comment évaluez-vous votre simulateur de remboursements ?
        </p>
        <div className="review-notes-wrapper">
          {[...Array(noteCount)].map((v, index) => index + 1).map(noteValue => (
             <div
                 key={noteValue}
                 className={`review-note${null !== note && noteValue <= note ? ' review-note--selected' : ''}`}
                 onClick={() => setNote(noteValue)}
             />
          ))}
        </div>
        <AnimateHeight duration={500} className="comment-wrapper" height={note !== null && note < 5 ? 'auto' : 0} animateOpacity>
          <textarea
              placeholder="Dites-nous comment l’améliorer ?"
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}>
            {comment}
          </textarea>
        </AnimateHeight>
        <a className="btn btn--fill btn--upper" onClick={onSubmit}>Envoyer</a>
      </div>
  );
};
