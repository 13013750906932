import React from 'react'
import Header from './Header'
import LimitedContainer from '../ui/LimitedContainer'

export const BasicLayout = ({ children }) => (
  <div className="app">
    <LimitedContainer>
      <Header />

      <section className="app-content">
        {children}
      </section>
    </LimitedContainer>
  </div>
)