import React from 'react'
import RadioButton from '../../../../components/ui/RadioButton'
import { Slider } from 'antd'
import { useSelector } from 'react-redux';
import { getCurrentQuestion } from '../../redux/steps-reducer';
import ClickTracker from '../../../../components/tracking/ClickTracker';
import { getChoiceTypeNumberById } from '../../../../lib/api/choiceTypeInformation';

export const DioptrySelector = ({ title, name, setSimulation }) => {
  const question = useSelector(getCurrentQuestion);

  return <div>
    <h3>{title}</h3>

    <div className="glass">
      <label>Type de verre</label>

      <ClickTracker chapter={question.title} level={getChoiceTypeNumberById('optiques')} name="Verres simples">
        <RadioButton
          name={name}
          value="simple"
          label="Verres simples"
          onClick={(e) => setSimulation(name, {'typeVerre': e.target.value})}
        />
      </ClickTracker>

      <ClickTracker chapter={question.title} level={getChoiceTypeNumberById('optiques')} name="Verres progressifs">
        <RadioButton
          name={name}
          value="progressif"
          label="Verres progressifs"
          onClick={(e) => setSimulation(name, {'typeVerre': e.target.value})}
        />
      </ClickTracker>
    </div>

    <div className="dioptry">
      <label>Correction</label>
      <Slider
        defaultValue={0}
        min={-12}
        max={12}
        step={1}
        marks={{ '-12': -12, '0': 0, '12': "+12" }}
        tooltipVisible
        tooltipPlacement="top"
        onChange={value => setSimulation(name, {'correction': value})}
      />
    </div>
  </div>
}